<template>
    <div class="container-inner">
        <div class="op-row ml-4 border-b">
            <div class="row" v-if="shared_tname">
                <div class="col-4 v-center pl-0">
                    <div class="lbl">{{ $t('party.table') }}</div>
                </div>

                <div class="col-8">
                    <div class="val">{{ shared_tname }}</div>
                </div>
            </div>
        </div>
        <!-- .op-row -->
        <div class="op-row ml-4 border-b" v-if="wait_code">
            <div class="row">
                <div class="col-4 v-center pl-0">
                    <div class="lbl">{{ $t('party.wait_code') }}</div>
                </div>

                <div class="col-8">
                    <div class="val">{{ wait_code }}</div>
                </div>
            </div>
        </div>
        <!-- .op-row -->
        <div class="op-row ml-4">
            <div class="row">
                <div class="col-4 v-center pl-0">
                    <div class="lbl">{{ $t('party.people_join') }}</div>
                </div>

                <div class="col-8">
                    <div class="val">{{ user_count }}</div>
                    <svg class="rt" width="24" height="24" viewBox="0 0 24 24" @click="go_to_order">
                        <path
                            d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <!-- .op-row -->
        <div class="op-row ml-4" v-if="shared_table_pswd">
            <div class="row">
                <div class="col-4 v-center pl-0">
                    <div class="lbl">{{ $t('party.verify_code') }}</div>
                </div>

                <div class="col-8">
                    <div class="val">{{ shared_table_pswd }}</div>
                </div>
            </div>
        </div>
        <!-- .op-row -->
        <div class="menus">
            <div v-for="(order, key) in user_foods" :key="key">
                <div class="menu">
                    <div class="user-status">
                        <div class="pt-1 color-yellow" v-html="getUserName(key.split('@')[0])"></div>
                        <svg
                            class="pp"
                            :style="getColorByName(key.split('@')[0])"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path
                                d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"
                            />
                        </svg>
                    </div>
                    <div v-if="order.length > 0">
                        <div class="row pt-2 pb-2" v-for="(item, key) in order" :key="key">
                            <div class="col-4">{{ translate('item', item.fname) }}</div>
                            <div class="col-4 text-center">x {{ item.count }}</div>
                            <div class="col-4 text-right">${{ getTotalPrice(item) }}</div>
                        </div>
                    </div>
                    <div v-else class="text-center text-muted">
                        {{ $t('party.nothing_ordered') }}
                    </div>
                    <!-- .row -->
                </div>
            </div>
            <!-- .menu -->
        </div>
    </div>
</template>
<style lang="scss" scoped>
.rt {
    position: absolute;
    right: 20px;
    bottom: -20px;
}
</style>
<script>
import Vue from 'vue';
import { Cell } from 'mint-ui';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import randomColor from 'randomcolor';

Vue.component(Cell.name, Cell);

export default {
    name: 'my_party',
    data() {
        return {
            oid: this.$route.query.oid || ''
        };
    },
    created() {
        if (this.oid) {
            this.initData({
                oid: this.oid.split('-')[0],
                cartid: this.$store.state.app.shared_cart_id
            });
        } else {
            this.initData({
                oid: '',
                cartid: this.$store.state.app.shared_cart_id
            });
        }
        this.set_app_title(this.$t('party.my_party'));
    },
    beforeMount() {
        this.timer = setInterval(() => {
            if (this.oid) {
                this.initData({
                    oid: this.oid.split('-')[0],
                    cartid: this.$store.state.app.shared_cart_id
                });
            } else {
                this.initData({
                    oid: '',
                    cartid: this.$store.state.app.shared_cart_id
                });
            }
        }, 5000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    computed: {
        ...mapGetters({
            translate: 'app/translate',
            user_count: 'app/get_shared_user_count',
            shared_tname: 'app/get_shared_tname',
            shared_table_pswd: 'app/get_table_pswd',
            wait_code: 'app/get_wait_code',
            language: 'app/get_language',
            uname: 'app/get_uname'
        }),
        ...mapState({
            user_foods: (state) => state.sharedorderdetail.user_foods,
            total_price: (state) => state.sharedorderdetail.total_price,
            total_price_vip: (state) => state.sharedorderdetail.total_price_vip,
            is_vip: (state) => state.sharedorderdetail.is_vip,
            no_data: (state) => state.sharedorderdetail.no_data
        }),
        randomUserIcon() {
            const color = randomColor();
            return `fill:${color}`;
        }
    },
    methods: {
        go_to_order(e) {
            this.$router.push({ path: '/sub/sharedorderdetail' });
        },
        ...mapActions({
            initData: 'sharedorderdetail/initData'
        }),
        ...mapMutations({
            set_app_title: 'app/APP_SET_APP_TITLE'
        }),
        getColorByName(name) {
            const color = randomColor({ seed: name });
            return `fill:${color}`;
        },
        getUserName(name) {
            let uname = name.toLowerCase().replace('guest', '#');
            if (this.uname && uname === this.uname.replace('Guest', '#')) {
                uname = `<span class="you">(${this.$t('party.you')})</span>`;
            }
            return uname;
        },

        getTotalPrice(item) {
            const itemPrice = this.is_vip == 1 ? item.fprice_vip : item.fprice;
            const itemSum = itemPrice;
            let extra_price = 0;
            if (item.addon_prices) {
                const addon_prices = item.addon_prices.split('@@');
                const addon_counts = item.addon_counts.split('@@');

                for (let i = 0, len = addon_prices.length; i < len; i++) {
                    const items = addon_prices[i].split('|');
                    const prices = addon_counts[i].split('|');
                    for (let j = 0; j < items.length; j++) {
                        extra_price += Number(items[j]) * Number(prices[j]);
                    }
                }
            }
            return ((Number(itemSum) + extra_price) * item.count).toFixed(2);
        }
    }
};
</script>
